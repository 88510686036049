@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');

$sidebar-bg-color: #171717f0;
$submenu-bg-color: rgba(255, 255, 255, 0.05);
$submenu-bg-color-collapsed: #171717f0;
$sidebar-color: black;
$highlight-color: #f23b5c;
$icon-bg-color: rgba(0, 0, 0, 0.05);
$icon-size: 35px;

@import '~react-pro-sidebar/dist/scss/styles.scss';
body {
  margin: 0;
  height: 100vh;
  color: #353535;
  font-family: 'Roboto', sans-serif;
}

.sidebar-header-welcome {
  height: 100px;
}

#root {
  height: 100%;
}

.app {
  height: 100%;
  display: flex;
  position: relative;
  min-height: 100vh; //new

  .btn-toggle {
    cursor: pointer;
    width: 35px;
    height: 35px;
    background: #353535;
    color: #fff;
    text-align: center;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    display: none;
  }

  .sidebar-btn-wrapper {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .sidebar-btn {
      padding: 1px 15px;
      border-radius: 40px;
      background: rgba(255, 255, 255, 0.05);
      color: #adadad;
      text-decoration: none;
      margin: 0 auto;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-overflow: ellipsis;
      overflow: hidden;
      span {
        margin-left: 5px;
        font-size: 13px;
      }
      &:hover {
        color: $highlight-color;
      }
    }
  }

  .collapsed {
    .sidebar-btn {
      display: inline-block;
      border-radius: 50%;
      width: 35px;
      height: 35px;
      line-height: 40px;
      padding: 0;
    }
  }

  main {
    //padding: 24px;
    //padding-top: 0px;
    //display: flex;
    flex-grow: 1;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
    flex-direction: column;
    overflow-y: auto;
    header {
      margin-bottom: 40px;
      border-bottom: 1px solid #efefef;
      h1 {
        display: flex;
        align-items: center;
        transform: translateX(-20px);
      }
    }

    footer {
      margin-top: auto;
      color: #888;
      text-align: center;
      a {
        text-decoration: none;
        color: #888;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  .social-bagdes {
    margin-top: 10px;
    img {
      margin: 5px;
    }
  }

  .block {
    display: flex;
    margin-bottom: 24px;
    font-size: 14px;
    color: #545454;
    > span {
      margin-left: 10px;
    }
  }

  &.rtl {
    direction: rtl;
    text-align: right;

    header {
      h1 {
        transform: translateX(20px);
      }
    }
    .block {
      direction: rtl;
      > span {
        margin-left: 0;
        margin-right: 10px;
      }
    }
    .sidebar-btn-wrapper {
      .sidebar-btn {
        span {
          margin-left: 0;
          margin-right: 5px;
        }
      }
    }
  }

  @media (max-width: 767.98px) {
    overflow-x: hidden;
    display: block;    
    header {
      h1 {
        font-size: 24px;
      }
    }
  }
  @media (max-width: $breakpoint-md) {
    .btn-toggle {
      display: flex;
    }

    &.rtl {
      .btn-toggle {
        margin-left: auto;
      }
    }
  }
}

.badge {
  padding: 3px 6px;
  font-size: 9px;
  letter-spacing: 1px;
  border-radius: 15px;
  &.red {
    color: #ffffff;
    background: #d63030;
  }
  &.gray {
    color: #ffffff;
    background: #777777;
  }
  &.yellow {
    color: #000000;
    background: #ffd331;
  }
}

.collapse-control {
  border: 0 !important;
  margin-bottom: 10px;
}

.collapse-control-menu {
  padding: 0px !important;
  top: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: left;
}

.collapse-control-out {
  padding: 0px !important;
  top: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: left;
  height: 100%;
  color: $highlight-color;
}

.pro-sidebar {
  width: 240px;
  min-width: 260px;
  background-color: #f4f5f7;

  @media (max-width: 767.98px) {
    width: 100% !important;
  }
  
}

.pro-sidebar > .pro-sidebar-inner {
  max-width: 250px;
  border-radius: 0px 10px 10px 0px;

  @media (max-width: 767.98px) {
    max-width: 100% !important;
  }
}

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item {
  color: white;

  a {
    color: white;
  }
}

.pro-sidebar .pro-menu.circle .pro-menu-item > .pro-inner-item > .pro-icon-wrapper {
  background-color: #818181;
}

.pro-sidebar .pro-menu.round .pro-menu-item > .pro-inner-item > .pro-icon-wrapper {
  background-color: #818181;
}

.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout .pro-sidebar-footer {
  border-top: 1px solid #818181;
}

.pro-icon {
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    height: 19px;
    width: 19px;
  }

}

.pro-menu-item {
  :hover {
    .pro-icon-wrapper .pro-icon {
      background-color: #ffffff;
      border-radius: 50%;
    }

    .pro-item-content {
      display: flex;
      background-color: #f23c5d;
      height: 35px;
      padding: 10px;
      padding-left: 15px;
      padding-right: 15px;
      border-radius: 15px;
      justify-content: center;
      align-items: center;
      position: fixed;
      z-index: -1;
      width: auto;
      left: 65px;
      color: white;
    }
  }
}

//Settings for mobile layout
.pro-sidebar {
  @media (max-width: 767.98px) {
    width: 100% !important;
  } 
}

.pro-sidebar > .pro-sidebar-inner {
  @media (max-width: 767.98px) {
    max-width: 100% !important;
    border-radius: 0px;
  }
}

.pro-sidebar-layout {
  @media (max-width: 767.98px) {
    height: 65px;
  }
}

.sidebar-lay {
  @media (max-width: 767.98px) {
    display: none !important;
  }
}

.mobile-lay {
  @media (min-width: 768.98px) {
    display: none !important;
  }
}

.app main {
  @media (max-width: 768.98px) {
    height: calc(100% - 62px);
  }
}

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:hover {
  .pro-icon-wrapper {
    background-color: #ffffff !important;
  }

  .pro-item-content {
    z-index: 99;
  }
}

